'use client'
import * as React from "react"
import { cn } from "@/lib/utils"
import { Label } from "./label"

export interface InputProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  label?: string
}

const Input = React.forwardRef<HTMLInputElement, InputProps>(
  ({ className, type, label, value, ...props }, ref) => {
    const [showLabel, setShowLabel] = React.useState(value !== "")

    React.useEffect(() => {
      setShowLabel(value !== "")
    }, [value])

    return (
      <div className={className}>
        {label && showLabel && <Label className="text-muted-foreground">
          {label}
        </Label>}
        <input
          type={type}
          className={cn(
            "flex h-10 w-full rounded-md border border-input bg-background px-3 py-2 text-sm ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50",
          )}
          ref={ref}
          value={value} // Add this line to pass the value prop to the input element
          {...props}
        />
      </div>
    )
  }
)

Input.displayName = "Input"
export { Input }